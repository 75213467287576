.pdf-backgd{
    background: #000000;
    padding: 5em 5em 0em;
}
.pdf-main-cont{
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
  
}
.pdf-cont-header{
    padding: 1em 3.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F3F2EF;
}
.pdf-section{
    display: flex;
    justify-content: center;
    padding: 2em 1.5em;
}

.pdf-iframe-cont{
    width: 90%;
//     background-color: #FFFFFF;
//     padding: 40px;
}
.pdf-header-left{
    display: flex;
    gap: 14px;
    width: 65%;
}
.pdf-header-left > div h4{
    margin-bottom: 2px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;

}
.pdf-left-tag{
    display: flex;
    gap: 16px;
    align-items: center;
}
.pdf-left-tag > div{
    display: flex;
    align-items: center;
}
.pdf-left-tag-views h3 {

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #0F973D;
}
.pdf-left-tag-tags h3{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
}
  



.pdf-left-tag p {
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;    
}


.pdf-header-btn{
    display: flex;
    gap: 12px;
}
.pdf-header-close-btn{
   
    padding: .5em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 27.4px;
    color: #4B4F57;
    height: 29px;
    cursor: pointer;
    
}


.pdf-header-report-btn{
    
    height: 25.5px;
    text-align: center;
    padding: .5em .75rem;
    border-radius: 8px;
    border: 1px solid #D42620;
    background: #FFFFFF;
    display: flex;
    align-items: center;
     gap: 8px;
}


.pdf-header-report-btn h4{

    font-size: 0.875rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #D42620;
    cursor: pointer;
}

.pdf-withdraw-modal{
    display: flex;
    flex-direction: column;
    height: 100%;
  
}
.pdf-withdraw-modal-top{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.pdf-header-reject-btn{
    
    height: 25.5px;
    text-align: center;
    padding: .5em .75rem;
    border-radius: 8px;
    border: 1px solid #D42620;
    background: #D42620;
    display: flex;
    align-items: center;
     gap: 8px;
}


.pdf-header-reject-btn h4{

    font-size: 0.875rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    cursor: pointer;
}

.pdf-report-btn-green{
    height: 25.5px;
    text-align: center;
    padding: .5em .75rem;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #007EFF;
    display: flex;
    align-items: center;
     
}
.pdf-report-btn-green h4{
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    cursor: pointer;
}
.pdf-icons{
    width: 18px;
    height: 18px;
    padding: 8px;
    border-radius: 20px;
    border: 1px solid #ECE9E4;
    margin-bottom: 20px;

}

.pdf-modal-cont > h4{
   
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    text-align: center;
    color: #000000;
    margin-bottom: 32px;
}
.pdf-modal-cont > h5{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    color: #101928;
    margin-bottom: 4px;

}

.pdf-modal-cont > textarea{
    height: 202px;
    padding: .5em .75em;
    border: 1px solid #DDD8D0;
    margin-bottom: 8px;
    width: 95%;
}

.pdf-modal-cont > p{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    text-align: left;
    color: #9CA0A5;
    margin-bottom: 32px;

}

.pdf-withdraw-modal{
    display: flex;
    flex-direction: column;
    height: 100%;
  
}
.pdf-withdraw-modal-top{
    display: flex;
    flex: 1;
   
    flex-direction: column;
    
}
.pdf-withdraw-text{
        
    width: 95%;
    height: 125px;
    padding: 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    margin: 20px  0px;
    

}
.pdf-withdraw-modal-top > h4{
     
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    text-align: center;
    color: #000;
    margin-bottom: 32px;

}
.pdf-withdraw-modal-top > h3{

    padding: 1em 2em;
    border-radius: 12px ;
    border: 1px solid #F9A410;
    font-size: 1.125rem;
    background: #FEF0D8;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 24px;

}
.pdf-withdraw-modal-top > p{
  
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 20px;

}

.pdf-withdraw-info{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5em;
    row-gap: 12px;
    border: 1px solid #DDD8D0;
    border-radius: 8px;

}
.pdf-withdraw-info > h3{
       
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;
  

}

.pdf-withdraw-info > p{
    
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #000;
 

}