.chart_header{

    font-size: 1.25rem;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #101928;
    margin-bottom: 26px;
}
.line_chart_cont{
    background: #FFFFFF;
    width: 57.2%;
    border-radius: 12px;
    padding: 1em;
    border: 1px solid #E4E4E7;  
    height: 259px;      
}