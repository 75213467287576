.donut_main{
    width: 32.8%;
    position:relative;
    background: #FFFFFF;
    border: 1px solid #E4E4E7; 
    border-radius: 12px; 
    // height: 258px;
    padding: 1em;
}
.donut_chart{
    margin-top: -116px;
    padding: 1em;
    text-align: center;
    margin-right: 134px;
}
.donut_chart strong {
    font-weight: 500;
}

.chart_header_donut{
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #101928;
    margin-bottom: 24px;

}