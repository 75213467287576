.recent-activity-main{
    background: #FBFBF9;
    border: 1px solid #E4E4E7;
    padding: 1.5em;
    border-radius: 12px;
    margin-top: 32px;
}

.recent-activity-body{
    padding: 2em;
    background: #FFFFFF;
    border: 1px solid #E4E4E7;
    border-radius: 12px;
}

.recent-activity-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.recent-activity-header > div{
    display: flex;
    align-items: center;
}
.recent-activity-header > div h4{
        
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #000000;
   
}

.recent-activity-header > h4{
           
  
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 31.2px;
    color: #000000;
}
.recent-activity-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}
.recent-activity-img{
    display: flex;
    gap: 28px;
}
.recent-activity-img > div  h4{
    
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    margin-bottom: 4px;
    color: #101928;
}
.recent-activity-img > div p{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #4B4F57;


}