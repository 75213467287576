.create-doc-banner{
   
    height: 45px;
    padding: 0.75em 2.5em ;
    background: #FFFFFF;

}
.create-doc-body{
    display: flex;
    justify-content: center;
    gap: 32px;
    background: #EEF4FA;
    min-height: 100vh;
    padding: 2.75em 0em;
}