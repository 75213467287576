.home_nav_cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
.home_nav_header{
    position: relative;
}
.home_nav_header {
    display: flex;
    gap: 8px;
    align-items: center;

}
.home_nav_header  h4{
  
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 36.4px;
    color: #000000;
    margin-bottom: 8px;
}

.home_nav_header p{


    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #4B4F57;
   
}
.home_nav_calendar{
    display: flex;
    gap:12px ;
    align-items: center;
}
.home_nav_calendar > div{
    display: flex;
    align-items: center;
    gap: 8px;
}

.home_nav_calendar_one{
   
    height: 26px;
    padding: 0.5em 0.75em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;

}
.home_nav_calendar_one p{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;

}

.new_consumer{
    // width:130px;
    height: 24px;
    padding:0.5em 0.75em;
    border-radius: 8px;
    align-items: center;
    color: #FFFFFF;
    display: flex;
    gap: 8px;
    background: #101928;

}
.new_consumer > h4{
    font-size: 0.875rem;
    font-weight: 400;
}
.home_nav_drop{
    background: #FFFFFF;
   
    position: absolute;
    width: 270px;
    border: 1px solid #ECE9E4;
    border-radius: 8px;
    top:88px ;
    
}
.home_nav_drop p {
    padding: 1em;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color:#101928 ;
}
.home_nav_drop p:hover{
    background: #FBFBF9;
}
