.login_page_cont{
    margin: 0 auto;
    padding: 7.5em 0em;
}
.login_header > h3{
    
    font-size: 2rem;
    font-weight: 700;
    line-height: 41.6px;
    letter-spacing: -0.005em;
    color: #000000;
    margin-bottom: 20px;

}
.login_header > p{
 
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    margin-bottom: 32px;

}
.login_header a{
    color: #007EFF;
    text-decoration: none;
}
.login_header span{

    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
  
}
.login_header span:hover{
    color: #3398FF;
}
.signup_email > h4,
.signup_pass > h4{
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 4px;
}
.remember_box{
    display: flex;
    justify-content: space-between;
    margin: 25px 0px;
}
.remember_box_one{
    display: flex;
    gap:8px;
}
.remember_box_one > p{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #101928;

}
.remember_box_two{
 
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color: #007EFF;

}
.remember_box_two :hover{
    color: #3398FF;;
}
.signup_name{
    display: flex; 
    gap: 31px;
}
.name_input{
    width: 165px;
}

.signup_name  input{
    width: 100%;
    height: 44px;
    padding: 0em 0.5em;
    border-radius: 6px;
    border: 1px solid #DDD8D0
}
.signup_email{
    margin-top: 16px;
}

.signup_email > div{
    width: 365px;
    border: 1px solid #DDD8D0;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 6px;
    gap: 8px;
    padding: 0em 0em 0em 0.5em;
}
.signup_email input{
    width: 100%;
    height: 44px;
    border: none;
    padding: 0em 0.5em;
    
}
.signup_pass{
    margin: 16px 0px 40px;
}
.signup_pass > div{
    width: 360px;
    border: 1px solid #DDD8D0;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 6px;
    gap: 8px;
    padding: 0em 0.5em 0em 0.5em;
}
.signup_pass input{
    width: 100%;
    height: 44px;
    border: none;
    padding: 0em 0.5em;
    
}

.helper_text{
  
font-size: 0.875rem;
font-weight: 400;
line-height: 22.4px;
letter-spacing: -0.015em;
text-align: left;
margin: 8px 0px 16px;
color: #667085;
}