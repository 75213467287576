.pagination-container {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
}

.record-range {
    font-size: 14px;
    color: #666;
}

.pagination {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.pagination button {
    padding: 0.25rem 0.5rem;
    border: none;
    background: none;
    color: #666;
    cursor: pointer;
    font-size: 0.75rem;
}

.pagination button.active {
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 50%;
    font-weight: bold;
}

.pagination button:disabled {
    cursor: not-allowed;
    color: #ccc;
}
.record-range p {
    
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    // color: #77787C;
}
.record-range span{
    color: #000;
}