.custom_select_container {
    position: relative;
    border-radius: 6px ;
    border: 1px solid #DDD8D0;
    background: #FFFFFF;
    cursor: pointer;
}
.custom_selected_option{
  
    display: flex;
    justify-content: space-between;
    
}
.custom_option_cont li{
   
    padding: 8px 10px;
}
.custom_option_cont li:hover{
    background: #F9F9F9
}
  .custom_option_cont{
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    list-style-type: none;
    width: 100%;
    z-index: 1;
    font-size: 1rem;
    overflow-y: auto;
    padding: .5em 0em;
  }
  .custom_selected_placeholder{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #B8B3A7;
  }
  .custom_selected_text{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #000000;
  }
  .custom_list{
    font-size: 0.875rem;
  }