.complete_page_cont{
    padding: 2.5em;
    text-align: center;
    margin: 0 auto;
    width: 45.5%;
    background: #FFFFFF;
    border-radius: 20px;
}
.complete_page_cont > h3{
  
    font-size: 2rem;
    font-weight: 600;
    line-height: 41.6px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 20px;
}
.complete_page_cont > p{

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #4B4F57;
    margin-bottom: 32px;
    
}

.complete_page_img{
 
    width: 300px;
    margin: 0 auto;
}
.complete_page_img img{
    width: 100%;
    object-fit: cover;
    margin-bottom: 32px;
}
