.navbar_cont{
    padding: 0.75em 2.5em;
    display: flex;
    align-items: center;
    gap: 24px;
}
.nav_links{
    display: flex;
    gap: 10px;
}
.nav_links > div{
    display: flex;
    gap: 6px;
    align-items: center;
}
.nav_links > div h4{
       
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    color: #007EFF
}
.nav_links > div h3{
       
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    color: #9CA0A5
}