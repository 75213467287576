.no_result_cont{
    background: #FFFFFF;
    text-align: center;
    padding-top: 2em;
}
.no_result_cont p{
    
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.01em;
  

}
.no_result_main{
    padding: 0em 2.5em 1.5em;;
}