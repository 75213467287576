.tree-map-cont{
   width: 100%;
   position: relative;
  
}
.tree-map-main{
 
   background: #FFFFFF;
   border-radius: 12px;
   padding: 1em;
   border: 1px solid #E4E4E7;  
}