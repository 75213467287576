.doc-acknowledge-cont{
    background: white;
    padding: 2em 1.5em 1.5em;
    border-radius: 10px;
    width: 40%;
    border: 1px solid #E4E7EC;
    height: 370px;
}   

.doc-acknowledge-header{
    text-align: center;
}
.doc-acknowledge-header h2{

    font-size: 1.5rem;
    font-weight: 600;
    line-height: 31.2px;
    color: #000000;
    margin-bottom: 8px;
}
.doc-acknowledge-header p{
        
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    margin-bottom: 32px;
}  
.doc-ack-content{
    display: flex;
    gap: 12px;
    margin-top: 12px;
}
.doc-ack-content p{
    color: #4B4F57;
}
.doc-ack-content a{
    color: #007EFF;
    text-decoration: none;
}
.report-temp-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}
.report-temp-cont > div{
    padding: 2em;
    background: #F6F6F6;
}