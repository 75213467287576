.primary_btn_onboard > button{
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    color: #FFFFFF;
    background-color: #007EFF;
    border-radius: 8px;
    border: 1px solid #007EFF;
}
.primary_btn_onboard > button:hover{
    background-color: #3398FF;
    border: 1px solid #3398FF;
}