.doc_summary_cont{
    background: white;
    padding: 2em 1.5em;
    border-radius: 10px;
    width: 40%;
    border: 1px solid #E4E7EC;
}
.doc_summary_cont_author{
    width: 96%;
    height: 30px;
    padding: .5em .75rem;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
  

}
.doc_summary_cont_source{
    width: 91%;
    height: 30px;
    padding: .5em .75rem;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
   

}
.doc_summary_cont_title{
    width: 95%;
    height: 30px;
    padding: .5em .75rem;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
   

}

.doc_summary_cont textarea{
    width: 95%;
    height: 97px;
    padding: 1em 1em 0em;
    border-radius: 6px;
    border: 1px solid #D0D5DD;
    
}

.doc_summary_cont form > div{
    margin-bottom: 24px;
}

.doc_summary_header{
    text-align: center;
}
.doc_summary_header h2{
      
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 31.2px;
    color: #000000;
    margin-bottom: 8px;
}
.doc_summary_header p{
        
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    margin-bottom: 32px;
}       
.doc_summary_cont h4{
            
  
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101828;
    margin-bottom: 4px;
}

.doc_summary_helper{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #9CA0A5;
    margin-top: 4px;
}
.tags_drop{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:29px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}
.tags_drop p{
    color: #9CA0A5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    
}
.tags_drop h4{
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    
}
.create_filter_keywd_main{
    position: absolute;
    width: 94%;
    height:249px;
    padding: 0.75em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    z-index: 2;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
}
.create_filter_keywd{
   
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 8px;
    row-gap: 24px;

}
.create_filter_keywd_grid{
    display: flex;
    align-items: center;
    gap: 8px;
}
.create_filter_keywd_grid h5{
     
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;

}
.create_filter_keywd_grid div{
    width: 16px;
    height: 16px;
}
.create_filter_keywd_grid img{
    width: 100%;
}


.create_filter_btn{
    display: flex;
    justify-content: end;
    margin-top: 24px;
}
.create_filter_btn button{
    width: 71px;
    height: 44px;
    padding: .5em .1em;
    border-radius: 8px;
    background-color: #007EFF;
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;    
    border: 1px solid #007EFF;
}
.create_filter_btn button:disabled{
    
    background-color: #DDD8D0;   
    border: 1px solid #DDD8D0;
}

.doc-summary-side{
    display: flex;
    gap: 20px;
    // justify-content: space-between;
}
.doc-summary-side > div{
    flex: 1;
}
.date_drop{
    
    display: flex;
    align-items: center;
    gap: 8px;
    height:27px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}
.date_drop > input{
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
}
.date_drop p{
    color: #9CA0A5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    
}
.date_drop h4{
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin: 0;
    
}

.create-doc-btn-cont{
    display: flex;
    justify-content: space-between;
   

}
.create-doc-btn-save{

    height: 44px;
    padding: .5em 1em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #FFFFFF;
    color: #007EFF;
    width: 43%;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;

}
.create-doc-btn-next{

    height: 44px;
    padding: .5em 1em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #007EFF;
    color: #FFFFFF;
    width: 43%;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;

}

.more_about_one_pp{
    margin:0px 0px 20px;
    cursor: pointer;
    position: relative;
}
.more_about_one_pp_header{

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #101928;
    margin: 32px 0px 8px;

}
.more_about_one_pp > div{
    
    height: 155px;
    padding: 1.5em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    background-color: #FFFFFF;
    text-align: center;

}
.more_about_one_pp span{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #77787C;

}
.click_drag_upload{
   
    font-size: 1rem;
    font-weight: 500;
    line-height: 26.4px;
    color:#007EFF ;
    margin: 20px 0px 8px ;

}
.click_svg{
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #98A2B3;
}

.more_about_one_pp input[type="file"] {
    display: none;
  }
  .loc_drop{
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:27px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}
.loc_drop p{
    color: #9CA0A5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    
}
.loc_drop h4{
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin: 0;
    
}
.create_doc_filter_lang{
    position: absolute;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    width: 19.5%;
    height: 122px;
    padding: 0.25em 0em;
    border-radius: 8px;
    z-index: 2;
}
.create_doc_filter_lang div{
    display: flex;
    align-items:center;
    gap: 8px;
    margin-bottom: 4px;
    padding: 0.5em 1em;
   
 
}

.create_doc_filter_lang > div h3{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;
}
