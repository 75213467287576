.create-doc-side-cont{
    padding: 1.5em;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #E4E7EC;
    border-radius: 10px;
}
.create-doc-side-cont.alt{
    height: 500px;
}

.create-doc-side-header{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.create-doc-side-num {
    background: #FFFFFF;
    border:1px solid #4B4F57;
    width: 48px;
    height: 48px;
    border-radius:48px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.create-doc-side-num.active {
    background: #007EFF;
    border: #007EFF;
    width: 48px;
    height: 48px;
    border-radius:48px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.create-doc-side-num h4{
    color: #4B4F57;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
}
.create-doc-side-num.active h4{
    color: #FFFFFF;
    
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
}


.create-doc-side-content h4{
            
    font-size: 16px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.02em;
    color: #4B4F57;;
    margin-bottom: 4px;

}
.create-doc-side-content.active h4{
            
    font-size: 16px;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.02em;
    color: #101928;
    margin-bottom: 4px;

}

.create-doc-side-content p{
     
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #4B4F57;

}
.create-doc-side-content.active p{
     
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 18.6px;
    color: #4B4F57;

}
.create-doc-supp-txt > h4{
    font-size: 1rem;
    font-weight: 600;
    line-height: 26.4px;
    letter-spacing: 0.02em;
    color: #344054;
    margin-bottom: 4px;
}

.create-doc-supp-txt p{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color:#9CA0A5;
    width: 70%;
    margin-bottom: 12px;
}
.create-doc-supp-txt > div{
    width: 19%;
    height:26px;
    padding: .5em 1em;
    border-radius: 8px ;
    border: 1px solid #9CA0A5;
    background: #FFFFFF;
    
    color: #9CA0A5;

}
.create-doc-supp-txt > div h4{
       
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;

}