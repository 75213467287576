
*{
  margin: 0;
  padding: 0;
  outline: none;
}
body {
  
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.onboard_cont{
  display: flex;
  background-color: #EEF4FA;
  min-height: 100vh;
}
input,textarea{
  font-family: 'Inter', sans-serif;
}
textarea{
  resize: none;
}
textarea::placeholder{
  font-family: 'Inter', sans-serif;
  color: #B8B3A7;
  font-size: 0.85rem;
}
input::placeholder{
  font-family: 'Inter', sans-serif;
  color: #B8B3A7;
  font-size: 0.875rem;
}
.dashboard-main{
  display: flex;
}
.dashboard-main > section{
  overflow: hidden;
  width: 100%;
}
button{
  font-family: 'Inter';
}

