.stats-cont{
    background: #FBFBF9;
    padding: 1em;
    border-radius: 12px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 16px;
    border: 1px solid #E4E4E7;
    margin-bottom: 32px;
}

.stats-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 12px;
    height: 206px;
    border: 1px solid #E4E4E7;
}
.stats-content div{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    
}
.stats-content div p{
          
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 26.1px;
    letter-spacing: -0.01em;
    color: #4B4F57;

}

.stats-content h4{
      
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: -0.005em;
    color: #101928;

}