
.create-news-cont{
    padding-top: 5em;
    background: gray;
    min-height: 100vh;
}

.create-news-banner{
   
    height: 45px;
    padding: 0.75em 2.5em;
    background: #FFFFFF;

}
.create-news-form-cont {
    background: #EEF4FA;
    min-height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 2.75em 0em 6em;
    
}
.create-news-form-box{
    width: 750px;
    height: 804px;
    background: #FFFFFF; 
    border: 1px solid #E4E7EC;
    border-radius: 10px;
    padding: 2em 1.5em;
    text-align: center;
}

.create-news-form-box > h2{  
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 31.2px;
    margin-bottom: 8px;

}

.create-news-form-box > p{
        
    font-size: 1rem;
    font-weight: 400;
    line-height: 26.4px;
    color: #4B4F57;
    margin-bottom: 32px;
}

.create-news-form-box > form input{
    width: 725px;    
    height:29px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;

}
.create-news-form-box > form textarea{
    width: 721px;    
    height:62px;
    padding: 1.75em 1em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;

}
.create-news-form-box > form h4{
    text-align: left;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    color: #101928;
    margin-bottom: 4px;


}
.create-news-form-box > form > div{
    margin-bottom: 24px;
}
.create-news-form-box > form > div > p{
    text-align: left;
    color: #9CA0A5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin-top: 8px;
}
.create-news-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}
.create-news-btn-save{
    width: 336px;
    height: 44px;
    padding: 0.5em 1em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #FFFFFF;
    color:#007EFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 22.4px;

}
.create-news-btn-submit{
    width: 336px;
    height: 44px;
    padding: 0.5em 1em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #007EFF;
    color:#FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 22.4px;
}

.side_inputs{
    display: flex;
    justify-content: space-between;
}
.embed_link{
    width: 339px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:29px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}

.lang_drop{
    width: 339px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:27px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}
.lang_drop p{
    color: #9CA0A5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    
}
.lang_drop h4{
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    margin: 0;
    
}
.tags_drop{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:29px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
}
.tags_drop p{
    color: #9CA0A5;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    
}
.tags_drop h4{
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    
}

.create_news_filter_lang{
    position: absolute;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    width: 364px;
    height: 122px;
    padding: 0.25em 0em;
    border-radius: 8px;
    z-index: 2;
}
.create_news_filter_lang div{
    display: flex;
    align-items:center;
    gap: 8px;
    margin-bottom: 4px;
    padding: 0.5em 1em;
   
 
}

.create_news_filter_lang > div h3{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;
}


.create_filter_btn{
    display: flex;
    justify-content: end;
    margin-top: 24px;
}
.create_filter_btn button{
    width: 71px;
    height: 44px;
    padding: .5em .1em;
    border-radius: 8px;
    background-color: #007EFF;
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;    
    border: 1px solid #007EFF;
}
.create_filter_btn button:disabled{
    
    background-color: #DDD8D0;   
    border: 1px solid #DDD8D0;
}
.create_filter_keywd_main{
    position: absolute;
    width: 93%;
    height:249px;
    padding: 0.75em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    z-index: 2;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
}
.create_filter_keywd{
   
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 8px;
    row-gap: 24px;

}
.create_filter_keywd_grid{
    display: flex;
    align-items: center;
    gap: 8px;
}
.create_filter_keywd_grid h5{
     
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;

}
.create_filter_keywd_grid div{
    width: 16px;
    height: 16px;
}
.create_filter_keywd_grid img{
    width: 100%;
}