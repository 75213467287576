.news_header{
    padding: 1.5em 2.5em .8em;
}
.news_header_btm{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.news_header_filter{
    display: flex;
    justify-content: space-between;
}
.news_header_filter_box{
    display: flex;
    align-items: center;
}
.news_header_search{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 350px;
    height: 28px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 0.5em 0.75em;
    border: 1px solid #DDD8D0;

}
.news_header_search > input{
    border: none;
    background: #FFFFFF;
    width: 100%;
}
.add_news{
    cursor: pointer;
}
.add_news > h1{

    font-size: 2rem;
    font-weight: 500;
    line-height: 41.6px;
    letter-spacing: -0.01em;
    margin-bottom: 24px;
}
.news_header_bkmk{
    display: flex;
    gap: 8px;
}
.news_header_tab {

    height: 21px;
    padding: 0.375em 0.85em;
    border-radius: 16px;
    border: 1px solid #DDD8D0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
}
.news_header_tab h4{
      
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #4B4F57;
    cursor: pointer;

}
.news_header_filter{
    gap: 16px;
}
.news_header_filter_box{
    gap: 8px;
    height: 21px;
    padding: 0.375em 0.85em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
}
.news_header_filter_box h4{
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #4B4F57;
    cursor: pointer;
}
.news_header_filter_box.active{
    gap: 8px;
    height: 21px;
    padding: 0.375em 0.85em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #EEF4FA;;
    display: flex;
    align-items: center;
}
.news_header_filter_box.active h4{
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 22.4px;
    color:#007EFF;
    cursor: pointer;
}
.news_filter_cont{
    position: relative;
}
.news_filter_drop{
    position: absolute;
    right: 40px;
    margin-top: 40px;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    width: 267px;
    height: 130px;
    padding: .25em 0em;
    border-radius: 8px;
    cursor: pointer;
    z-index: 2;
}

.add_news{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.add_news div{
    width:98px;
    height: 24px;
    padding:0.5em 0.75em;
    border-radius: 8px;
    align-items: center;
    color: #FFFFFF;
    display: flex;
    gap: 8px;
    background: #101928;

}
.add_news div h4{
           
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    

}
.news_filter_drop > div{
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 0.5em 1em;
 
}
.news_filter_drop div:hover{
    background: #FBFBF9;
}
.news_filter_drop > div h4{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;
}
.news_filter_lang{
    position: absolute;
    right: 313px;
    margin-top: 40px;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    width: 150px;
    height: 122px;
    padding: 0.25em 0em;
    border-radius: 8px;
    z-index: 2;
}
.news_filter_lang div{
    display: flex;
    align-items:center;
    gap: 8px;
    margin-bottom: 4px;
    padding: 0.5em 1em;
   
 
}

.news_filter_lang > div h4{
    
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;
}
.news_filter_date{
    position: absolute;
    right: 313px;
    margin-top: 98px;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    width: 232px;
    height: 256px;
    padding: 1em;
    border-radius: 8px;
    z-index: 2;
}
.news_filter_date > p{
    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;
    color:#9CA0A5 ;
    margin-bottom: 8px
}
.news_filter_date_range {
    margin-bottom: 16px;
}
.news_filter_date_range h4{
    
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    color: #101928;
    margin-bottom: 4px;
}
.news_filter_date_range > div{
    width: 207px;
    height: 26.5px;
    padding: 0.5em 0.75em;
    border-radius: 6px;
    border: 1px solid #DDD8D0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.news_filter_date_range p{
              
   
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
   
}

.news_filter_btn{
    display: flex;
    justify-content: end;
    margin-top: 24px;
}
.news_filter_btn button{
    width: 71px;
    height: 44px;
    padding: .5em .1em;
    border-radius: 8px;
    background-color: #007EFF;
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22.4px;    
    border: 1px solid #007EFF;
}
.news_filter_btn button:disabled{
    
    background-color: #DDD8D0;   
    border: 1px solid #DDD8D0;
}
.news_filter_keywd_main{
    position: absolute;
    width: 480px;
    height:362px;
    padding: 0.75em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    right: 313px;
    margin-top: 140px;
    z-index: 2;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
}
.news_filter_keywd{
   
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 8px;
    row-gap: 24px;

}
.news_filter_keywd_grid{
    display: flex;
    align-items: center;
    gap: 8px;
}
.news_filter_keywd_grid h4{
     
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;

}
.news_filter_keywd_grid div{
    width: 16px;
    height: 16px;
}
.news_filter_keywd_grid img{
    width: 100%;
}
.filter_items{
    display: flex;
    gap: 24px;
    margin-top: 16px;
}
.filter_items > div{
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    height: 20px;
    padding: 0.375em 0.75em;
    border-radius: 8px;
    border: 1px solid #007EFF;
    background: #EEF4FA;
    margin-bottom: 16px;

}

.filter_items > div p{

    
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 21.4px;
    color: #007EFF;

}
.news_header_tab.active {
    border: 1px solid #007EFF;
    background: #EEF4FA;
   
}
.news_header_tab.active h4{
   
    color: #007EFF;
}
.sort_dropdown{
    position: relative;
}
.sort_dropdown_head{
    gap: 8px;
    height: 21px;
    padding: 0.375em 0.85em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    background: #FFFFFF;
    display: flex;
    align-items: center;
}
.sort_dropdown_head > h4{
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 22.4px;
    color: #4B4F57;
    cursor: pointer;
}
.sort_dropdown_cont{
    position: absolute;
    width: 237px;
    height:250px;
    // padding: 0.75em 1em;
    border-radius: 8px;
    border: 1px solid #DDD8D0;
    right: 0px;
    margin-top: 5px;
    z-index: 2;
    box-shadow: 0px 4px 9px -2px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
}
.sort_dropdown_cont > div {
    display: flex;
    align-items: center;
    justify-content:  space-between;
    padding: 0.6em 1em;
    border-radius: 8px;
}
.sort_dropdown_cont > div:hover{
    background: #FBFBF9;
}
.sort_dropdown_cont > div h4{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.015em;
    color: #101928;
    cursor: pointer;
}