/* Tooltip.css */
.tooltip-container {
    position: relative;
    display: flex;
  }
  
  .tooltip-text {
    height: auto;
    
    /* white-space: nowrap; */
    font-size: 13px;
    line-height: 19.5px;
    visibility: hidden;
    background-color: #030402;
    color: #fff;
    border-radius: 2px;
    padding: 8px;
    position: absolute;
    z-index: 1; 
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  
  
.tooltip-text::before {
  content: "";
  position: absolute;
  top: 100%;
  transform: translateX(100%);
  border-width: 6px; 
  border-style: solid;
  border-color: #030402 transparent transparent transparent; 
}