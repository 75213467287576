.admin_home_cont{
    display: flex;
    align-items: flex-start;
}
.admin_home_side{
    background: #FFFFFF;
    padding: 3em 2.5em;
    width: 100%;
}
.admin_chart_one{
    background: #FBFBF9;
    padding: 1em;
    border-radius: 12px;
    border: 1px solid #E4E4E7;
   
    
}
.admin_charts_box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}