.dash-loader{
    background:url(../../../../assets/dashbg.jpg);
    background-size: 100%;
    height: 100vh;
}
.dash-loader > div{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
}